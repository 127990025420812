/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// ion-label {
//     color: #666 !important;
// }

ion-item-divider {
    background-color: #eee;
    padding-top: 5px;
    padding-bottom: 5px;
}

ion-back-button {
    padding-left: 5px;
    padding-right: 5px;
}

.mapLabel {
    font-size: 10pt;
    color: white;
    font-weight: bold;
    text-align: center;
    background-color: #333;
    padding: 0px 3px;
}

ion-button {
    font-weight: bold;
    text-transform: uppercase;
}